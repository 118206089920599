<template>
  <div>
    <div style="height:110vh;" class="wrap">
      <div class="con">
        <div class="con_table">
          <div class="con_top mb-10">
            <h2><span>■</span> 공지 상세정보</h2>
          </div>

          <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
            <tbody v-for="(item, index) of boardList" :key="index">
              <tr>
                <th>제목</th>
                <td class="left pl-20">
                  {{ item.board_title }}
                </td>
                <th class="w-120px">작성자</th>
                <td class="left pl-20">
                  {{ item.kname }}
                </td>
              </tr>
              <tr>
                <th>노출 구분</th>
                <td class="left pl-20">
                  {{ item.board_gubun }}
                </td>
                <th class="w-120px">첨부파일</th>
                <td class="left pl-20">
                  <span class="pointer download" v-if="isNull(item.board_file_name) && isNull(item.board_file_url)" @click="newOpen(item.board_file_url)">
                    {{ item.board_file_name }}
                  </span>
                  <span v-else>
                    없음
                  </span>
                </td>
              </tr>
              <tr>
                <th class="w-120px">내용</th>
                <td class="con-detail left pl-20" colspan="3">
                  <div v-html="item.board_contents"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="comment-container">
        <img class="comment-icon" src="@/assets/images/re_icon.png" />
        <div class="comment-title">댓글</div>
        <div class="comment-number">{{ commentCnt }}</div>
      </div>

      <div v-for="(item, index) in commentList" :key="index" class="comment-list-container">
        <div class="comment-list">
          <!-- 댓글 -->
          <div class="comment-all-content-container">
            <div class="comment-title-container">
              <div class="comment-title-left-container">
                <div class="comment-name">
                  {{ item.name }}
                </div>
                <div class="comment-day">
                  {{ item.commentCreateDate }}
                </div>
              </div>

              <div v-if="getUserInfo.id == item.userId" class="comment-btn-right-container">
                <button @click="handleEditComment(item)" class="comment-edit-btn pointer">수정</button>
                <button @click="handleDelComment(item)" class="comment-delete-btn pointer">삭제</button>
              </div>
            </div>
            <div class="comment-detail-content">
              {{ item.comment }}
            </div>
            <button v-if="isShowReCommentBtn" @click="handleAddComment(item)" :style="item.reComment ? 'background-color: #fdd000;' : 'background-color: #fbfbfb;'" class="comment-reply pointer">
              답글
            </button>

            <!-- 댓글/대댓글 수정 -->
            <div v-if="item.modify" class="comment-reinput-title-container1">
              <div class="comment-reinput-btn">
                <textarea class="comment-reinput-textarea" v-model="regParam.comment" placeholder="댓글을 수정해주세요."></textarea>
                <button @click="handleEditCommentReg(item)" class="comment-pointer pointer">수정</button>
              </div>
            </div>

            <!-- 답글 -->
            <div v-if="item.reComment" class="comment-reinput-title-container1">
              <div class="comment-reinput-btn">
                <textarea class="comment-reinput-textarea" v-model="regParam.comment" placeholder="댓글을 남겨주세요."></textarea>
                <button @click="handleCommentInsert(item)" class="comment-pointer pointer">등록</button>
              </div>
            </div>
          </div>

          <!-- 대댓글 -->
          <template v-if="item.list.length > 0">
            <div class="comment-re-title-container" v-for="(subitem, subindex) in item.list" :key="subindex">
              <img class="comment-re-img" src="@/assets/images/re.png" />

              <div class="comment-re-title-container-box">
                <div class="comment-re-title-left-container">
                  <div class="comment-re-name">
                    {{ subitem.name }}
                  </div>
                  <div class="comment-re-day">
                    {{ subitem.commentUpdateDate }}
                  </div>
                </div>

                <div v-if="getUserInfo.id == subitem.userId" class="comment-re-btn-right-container">
                  <button @click="handleEditComment(subitem)" class="comment-edit-btn pointer">수정</button>
                  <button @click="handleDelReComment(subitem)" class="comment-delete-btn pointer">삭제</button>
                </div>
              </div>

              <div class="comment-re-content">
                {{ subitem.comment }}
              </div>

              <div v-if="subitem.modify" class="comment-reinput-title-container1 recomment">
                <div class="comment-reinput-btn">
                  <textarea class="comment-reinput-textarea" v-model="regParam.comment" placeholder="댓글을 수정해주세요."></textarea>
                  <button @click="handleEditCommentReg(subitem)" class="comment-pointer pointer">수정</button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- 댓글 등록 -->
      <div style="position:relative;">
        <textarea class="comment-reinput-textarea" v-model="param.comment" placeholder="댓글을 남겨주세요."></textarea>
        <button @click="handleCommentRegistration" class="comment-pointer pointer" style="">등록</button>
      </div>

      <div class="btns3 mgB80">
        <a class="btn_sms pointer mr-10" @click="moveToNoticeList()">목록</a>
        <a class="btn_cancle pointer" @click="moveToNoticeModify(idx)">수정</a>
        <a class="btn_cancle pointer" style="margin-left:10px" @click="deleteNotice(idx)">삭제</a>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "HappycrmFrontDetail",

  data: () => ({
    idx: -1,
    boardList: [],
    commentCnt: 0,
    baordFileUrl: "",
    boardFileName: "",

    page: 1,
    pagesize: 50,
    commentContainerList: {
      commentList: [],
    },
    commentList: [],
    isShowReCommentBtn: true,
    reComment: false,
    editComment: false,
    editReComment: false,
    crmBoardIdx: "",

    param: {
      idxBoard: "",
      commentDepth: "",
      idxParentComment: "",
      comment: "",
      depth: "",
    },
    regParam: {
      comment: "",
    },
    reInputParam: {
      idxBoard: "",
      commentDepth: "",
      idxParentComment: "",
      comment: "",
      depth: 2,
    },
    reginputParam: {
      comment: "",
    },
  }),
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  mounted() {
    const { id } = this.$route.params;
    this.getBoardList(id);
    this.idx = id;
    this.crmBoardIdx = id;
    this.getCommentList();
  },

  methods: {
    isNull(val) {
      if (val == null || val === "null" || !val) {
        return false;
      }
      return true;
    },
    newOpen(path) {
      window.open(path, "_blank");
    },
    // 공지 수정 페이지 이동
    moveToNoticeModify(idx) {
      this.$router.push(`/origin/notice/modify/${idx}`);
    },
    // 공지 삭제
    deleteNotice(idx) {
      var params = {
        idx: idx,
      };
      this.axios
        .delete(`/api/v1/board/${idx}`, { params: params })
        .then((res) => {
          if (res.data.err === 0) {
            alert("삭제 완료");
          } else if (res.data.err === 403) {
            alert("권한이 없습니다.");
          } else {
            alert("관리자에게 문의 바랍니다.");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.$router.push("/origin/notice");
    },

    //공지사항 목록 페이지 이동
    moveToNoticeList() {
      this.$router.push("/origin/notice");
    },
    // 공지 리스트 출력
    getBoardList(idx) {
      var params = {
        idx: idx,
      };

      this.axios
        .get(`/api/v1/board/${idx}`, { params: params })
        .then((res) => {
          if (res.data.err === 0) {
            this.boardList = res.data.boardList;
            if (res.data.boardList.length >= 1) {
              this.commentCnt = res.data.boardList[0].commentCnt;
            }
          } else {
            this.boardList = [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCommentList() {
      // 대댓글 리스트 조회
      this.axios.get(`/api/v1/board/comment/${this.crmBoardIdx}`).then((res) => {
        if (res.data.comment.length > 0) {
          res.data.comment.forEach((item) => {
            item["reComment"] = false;
            item["modify"] = false;

            if (item.list.length > 0) {
              item.list.forEach((subitem) => {
                subitem["reComment"] = false;
                subitem["modify"] = false;
              });
            }
          });

          this.commentList = res.data.comment;
        }
      });
    },
    handleCommentRegistration() {
      // 댓글 등록
      let params = {
        idxBoard: this.crmBoardIdx,
        commentDepth: (this.param.commentDepth = 1),
        idxParentComment: this.param.idxParentComment,
        comment: this.param.comment,
      };
      this.axios.post("/api/v1/board/insertBoardComment", params).then((res) => {
        (this.param.comment = ""), (this.param.idxBoard = ""), (this.param.commentDepth = ""), (this.param.idxParentComment = ""), (this.commentContainerList.commentList = res.data.comment);
        this.getCommentList();
      });
    },
    handleEditComment(item) {
      console.log("item :", item);
      item.modify = !item.modify;
      item.reComment = false;
      this.regParam.comment = item.comment;
    },
    handleAddComment(item) {
      item.reComment = !item.reComment;
      item.modify = false;
      this.regParam.comment = "";
    },
    handleEditCommentReg(item) {
      // 댓글 수정 등록
      item.comment = this.regParam.comment;
      this.axios.put(`/api/v1/board/updateBoardComment/${item.idx}`, item).then((res) => {
        this.getCommentList();
      });
    },
    //대댓글
    handleCommentInsert(item) {
      const newComment = {
        idxBoard: this.crmBoardIdx,
        commentDepth: 2,
        idxParentComment: item.idx,
        comment: this.regParam.comment,
      };
      this.axios.post("/api/v1/board/insertBoardComment", newComment).then((res) => {
        //.
        if (res.data.err == 0) {
          alert("등록 되었습니다.");
          this.getCommentList();
        }
      });
    },

    handleDelComment(item) {
      // 댓글 삭제
      Swal.fire({
        title: "댓글",
        text: "삭제하시겠습니까?",
        showCancelButton: true,
        confirmButtonColor: "#fdd000",
      }).then((res) => {
        if (res.isConfirmed == true) {
          axios.put(`/api/v1/board/comment/${item.idx}`).then((res) => {
            if (res.data.err == 0) {
              alert("삭제 하였습니다.");
              this.getCommentList();
            }
          });
        }
      });
    },
    handleDelReComment(subitem) {
      // 대댓글 삭제
      Swal.fire({
        title: "댓글",
        text: "삭제하시겠습니까?",
        showCancelButton: true,
        confirmButtonColor: "#fdd000",
      }).then((res) => {
        if (res.isConfirmed == true) {
          axios.put(`/api/v1/board/comment/${subitem.idx}`).then((res) => {
            if (res.data.err == 0) {
              alert("삭제 하였습니다.");
              this.getCommentList();
            }
          });
        }
      });
    },
    handleReComment() {
      // 대댓글
      this.reComment = !this.reComment;
    },
    handlReEditComment() {
      // 대댓글 수정
      this.editReComment = !this.editReComment;
    },
    handleReCommentReg() {
      // 대댓글 수정 등록
      let params = {
        idx: this.idx,
        reginputParam: this.reginputParam.comment,
      };
      this.axios.put(`/api/v1/board/updateBoardComment/${this.idx}`, params).then((res) => {
        console.log(res.data);
      });
    },
    handleReRegComment() {
      // 대댓글 등록
      let params = {
        idxBoard: this.reInputParam.idxBoard,
        commentDepth: (this.reInputParam.commentDepth = 2),
        idxParentComment: this.reInputParam.idxParentComment,
        comment: this.reInputParam.comment,
      };
      this.axios.post("/api/v1/board/insertBoardComment", { params: params }).then((res) => {
        console.log(res.data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.con-detail {
  white-space: break-spaces;
}
.textarea::placeholder {
  font-size: 13.5px;
  color: #656565;
}
.comment-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .comment-icon {
    width: 24px;
    height: 24px;
    padding-right: 8px;
  }
  .comment-title {
    font-size: 13.5px;
    padding-right: 5px;
    font-weight: bold;
  }
  .comment-number {
    font-size: 13.5px;
    font-weight: bold;
  }
}
.comment-list-container {
  margin: 0 21px 0 0;
  padding: 0px 32px 22px 31px;
  background-color: #fbfbfb;
  .comment-list {
    .comment-all-content-container {
      border-bottom: solid 1px #e1e1e1;
      padding-bottom: 22px;
      padding-top: 22px;
    }
    .comment-title-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      .comment-title-left-container {
        display: flex;
        align-items: center;
        .comment-name {
          font-size: 13.5px;
          color: #656565;
          font-weight: bold;
          padding-right: 10px;
        }
        .comment-day {
          font-size: 11px;
          color: #acafaf;
        }
      }
      .comment-btn-right-container {
        .comment-edit-btn {
          font-size: 11px;
          height: 22px;
          margin-right: 8px;
          background-color: #f4f4f4;
          border-radius: 3px;
          border: solid 1px #656565;
        }
        .comment-delete-btn {
          font-size: 11px;
          height: 22px;
          background-color: #f4f4f4;
          border-radius: 3px;
          border: solid 1px #656565;
        }
      }
    }
    .comment-detail-content {
      font-size: 13.5px;
      margin-bottom: 12px;
    }
    .comment-reply {
      font-size: 12px;
      border-radius: 3px;
      border: solid 1px #fdd000;
      background-color: #fbfbfb;
    }
    .comment-re-title-container {
      align-items: center;
      border-bottom: solid 1px #e1e1e1;
      padding: 20px 24px 22px 23px;
      background-color: #f4f4f4;
      position: relative;
      .comment-re-img {
        top: 20px;
        width: 10px;
        height: 10px;
        position: absolute;
      }
      .comment-re-title-container-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .comment-re-title-left-container {
          display: inline-flex;
          .comment-re-name {
            padding-right: 10px;
            font-size: 13.5px;
            font-weight: bold;
            margin-left: 20px;
          }
          .comment-re-day {
            font-size: 11px;
            color: #acafaf;
          }
        }
        .comment-re-btn-right-container {
          .comment-edit-btn {
            font-size: 11px;
            height: 22px;
            margin-right: 8px;
            background-color: #f4f4f4;
            border-radius: 3px;
            border: solid 1px #656565;
          }
          .comment-delete-btn {
            font-size: 11px;
            height: 22px;
            background-color: #f4f4f4;
            border-radius: 3px;
            border: solid 1px #656565;
          }
        }
      }
      .comment-re-content {
        font-size: 13.5px;
        margin-left: 20px;
        margin-top: 12px;
      }
      .comment-re-detail-content {
        margin-top: 12px;
        font-size: 13.5px;
        margin-bottom: 12px;
      }
    }

    .comment-reinput-title-container1 {
      &.recomment {
        background: none;
      }
      align-items: center;
      border-bottom: solid 1px #e1e1e1;
      margin: 0 0 0 1px;
      padding: 20px 24px 22px 23px;
      background-color: #f4f4f4;
      position: relative;
      padding: 0px 0px 0px 0px;
      border-bottom: none;
      background-color: #fbfbfb;
      .comment-reinput-btn {
        position: relative;
      }
      .comment-reinput-textarea {
        resize: none;
        border-radius: 0px;
        width: 1218px;
        height: 59px;
        margin: 11px 23px 11px 22px;
        border: solid 1px #e1e1e1;
        width: -webkit-fill-available;
        background-color: #fff;
      }
      .comment-pointer {
        position: absolute;
        right: 50px;
        top: 31px;
        border-radius: 3px;
        width: 62px;
        height: 28px;
        border: solid 1px #fdd000;
        background-color: #fdd000;
      }
      .comment-reinput-img {
        top: 20px;
        width: 10px;
        height: 10px;
        position: absolute;
      }
      .comment-reinput-title-container-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .comment-reinput-title-left-container {
          display: inline-flex;
          .comment-reinput-name {
            padding-right: 10px;
            font-size: 13.5px;
            font-weight: bold;
            margin-left: 20px;
          }
          .comment-reinput-day {
            font-size: 11px;
            color: #acafaf;
          }
        }
        .comment-reinput-btn-right-container {
          .comment-edit-btn {
            font-size: 11px;
            height: 22px;
            margin-right: 8px;
            background-color: #f4f4f4;
            border-radius: 3px;
            border: solid 1px #656565;
          }
          .comment-delete-btn {
            font-size: 11px;
            height: 22px;
            background-color: #f4f4f4;
            border-radius: 3px;
            border: solid 1px #656565;
          }
        }
      }
      .comment-reinput-detail-content {
        margin-top: 12px;
        font-size: 13.5px;
        margin-bottom: 12px;
      }
    }
    .comment-reinput-title-container2 {
      padding: 0px 0px 0px 0px;
      border-bottom: none;
      .comment-reinput-btn {
        position: relative;
      }
      .comment-reinput-textarea {
        resize: none;
        border-radius: 0px;
        width: 1218px;
        height: 59px;
        margin: 11px 23px 11px 22px;
        border: solid 1px #e1e1e1;
        width: -webkit-fill-available;
        background-color: #fff;
      }
      .comment-pointer {
        position: absolute;
        right: 50px;
        top: 31px;
        border-radius: 3px;
        width: 62px;
        height: 28px;
        border: solid 1px #fdd000;
        background-color: #fdd000;
      }
    }
    .comment-reinput-title-container3 {
      position: relative;
      padding: 0px 0px 0px 23px;
      .comment-reinput-img {
        top: 20px;
        width: 10px;
        height: 10px;
        position: absolute;
      }
      .comment-reinput-textarea {
        resize: none;
        border-radius: 0px;
        width: 1218px;
        height: 59px;
        margin: 11px 23px 11px 22px;
        border: solid 1px #e1e1e1;
        width: -webkit-fill-available;
        background-color: #fff;
      }
      .comment-pointer {
        position: absolute;
        right: 50px;
        top: 31px;
        border-radius: 3px;
        width: 62px;
        height: 28px;
        border: solid 1px #fdd000;
        background-color: #fdd000;
      }
    }
  }
}
.comment-reinput-textarea {
  resize: none;
  border-radius: 0px;
  height: 115px;
  margin: 65px 0px 52px 0;
  padding: 14px 32px 21px 17px;
  border: solid 1px #e1e1e1;
  width: -webkit-fill-available;
  background-color: #fff;
}
.comment-pointer {
  position: absolute;
  right: 32px;
  top: 166px;
  border-radius: 3px;
  width: 62px;
  height: 28px;
  border: solid 1px #fdd000;
  background-color: #fdd000;
}
</style>
